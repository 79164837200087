/* eslint-disable no-undef */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Loader } from 'google-maps';
import { get } from 'lodash';

$(document).ready(function() {
  $('#batch_errors').hide();
    $('.dataTables-products').DataTable({
      pageLength: 3000,
      responsive: true,
      dom: '<"html5buttons"B>lTfgitp',
      buttons: [{
          extend: 'copy'
        },
        {
          extend: 'csv'
        },
        {
          extend: 'excel',
          title: 'Products_Excel'
        },
        {
          extend: 'pdf',
          title: 'Products_PDF'
        },

        {
          extend: 'print',
          customize: function(win) {
            $(win.document.body).addClass('white-bg');
            $(win.document.body).css('font-size', '10px');

            $(win.document.body).find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          }
        }
      ]
    });

    $('body').on('click', '#submit_button', function (event) {
      event.preventDefault();
      var quantityList = document.querySelectorAll('.quantity');
      var quantityItem = [...quantityList].map(item=>{if(item.value) {return {[item.name] : item.value}}}).filter(item => item)
      $('#submit_button').prop("disabled", "disabled");
      $.ajax({
          type: "POST",
          url: "/transfer_batch",
          data: {products: quantityItem,location: gon.location, transfer_location_id: gon.transferLoc, brand_id: gon.brandId},
          success: function(result) {
            if (!result.success) {
              if(typeof(result.wrong_transfer_list)  != "undefined" && result.wrong_transfer_list != null){
                var wrong_transfers = result.wrong_transfer_list.split(',');
                for (var i = 0; i < wrong_transfers.length; ++i) {
                  var product_object = $( "input[name=" + wrong_transfers[i] + "]" )
                  product_object.addClass("quantity input-validation-error")
                }
              }
              displayErrors(result.error);
            } else {
              var locationRedirectUrl = '/batch_stock_transfer?location=' + result.location;
              if(result.transfer_location_id){
                locationRedirectUrl = locationRedirectUrl + '&transfer_location_id=' + result.transfer_location_id;
              }
              if(result.brand_id){
                locationRedirectUrl = locationRedirectUrl + '&brand_id=' + result.brand_id;
              }
              window.location.replace(locationRedirectUrl);
            }
            $('#submit_button').prop("disabled", false);
          },
          error: function(result) {
            console.log(get(result, 'responseJSON.error.message'));
              if (get(result, 'responseJSON.error.message')) {
                const errorMessage = get(result, 'responseJSON.error.message');
                const errorElement = $('<li></li>').text(errorMessage);
                console.log("errorElement: ", errorElement)
                displayErrors(errorElement);
              }
            $('#submit_button').prop("disabled", false);
          }
      });
    });
  });
  function displayErrors(errors) {
    $('#batch_errorsUl').html(errors);
    $('#batch_errors').show();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
